/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2023-02-07 15:27:07
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-07 16:17:45
 */
import http from "./axios_init";
export default {
  /**
   * 获取可用的记账项列表
   * @returns
   */
  get_usable_types() {
    return http.get("seller/v1/tallyBook/type/usable");
  },
};
